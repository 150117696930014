/**
 * Direttiva che costruisce la toolbar
 */
import { SenecaResponse, User, Item } from "atfcore-commonclasses";

export interface IToolbarDirectiveScope extends ng.IScope {
	isTooltipVisible: boolean;
	toggleTooltipVisibility: Function;
	goToNewGroup: Function;
	goToNewLearningPlan: Function;
	toggleResultView: Function;
	toggleIndicatorView: Function;
	forceToggleIndicator: boolean;
	isCardResultView: boolean;
	isToggleResultViewVisible: boolean;
	windowWidth: number;
	isToggleResultViewAutomaticChanged: boolean;
	isWidthExceeded: boolean;
	historyBack: Function;
	tooltipsVisibility: any;
	shareItem: Function;
	itemId: string;
	addItemToLearningPlan: Function;
	isLearningPlan: boolean;
	deleteItem: Function;
	goToEditGroup: Function
	moveBackDisabled: Function;
	selectedItems: Array<any>;
	itemChildsCount: number;
	moveForwardDisabled: Function;
	goToHome: Function;
	addToLpMenuVisible: boolean;
	libraryApplicationData: any;
	goToNewProject: Function;
	canManageProject: boolean;
	goToEditProject: Function;
	deleteProject: Function;
	addSectionToProject: Function;
	adminMode: boolean;
	goToNewItem: Function;
	goToEditItem: Function;
	isProject: any;
	page: string;
	project: any;
	filters: any;
	isItemPublic: boolean;
	isMyItem: boolean;
	showBackBtn: boolean;
	breadcrumbs: any;
	showToggleCardBtn: boolean;
	isToggleCardBtnDisabled: boolean;
	hideActions: boolean;
	isNewGroupBtnVisible: boolean;
	isNewItemBtnVisible: boolean;
	isNewTemplateBtnVisible: boolean;
	isNewLearningPlanBtnVisible: boolean;
	isNewProjectBtnVisible: boolean;
	isEditBtnVisible: boolean;
	currentAdminTab: any;
	textTemplateType: any;
	canGoToEdit: any;
	isDuplicateItemBtnVisible: boolean;
	isAddAtomicItemBtnVisible: boolean;
	isAddSectionToProjectBtnVisible: boolean;
	isAddUsersToGroupBtnVisible: boolean;
	isShareItemBtnVisible: boolean;
	isAddVisibilityToUserOrGroupBtnVisible: boolean;
	isAddItemToLearningPlanBtnVisible: boolean;
	goToNewTemplate: Function;
	isTenantOwner: boolean;
	hasUserAtLeastOneAdminTenant: boolean;
	isDeleteBtnVisible: Function;
	isAtLeastOneItemChildChecked: Function;
}
angular.module('app').directive("toolbar", function ($window, $state, $stateParams, $mdDialog, blockUI, VisibilityService, toaster, $translate, $rootScope, $timeout, LearningPlanService, LibraryApplicationData, UserService, $sessionStorage, SessionStorageService, GlobalApplicationData) {
	return {
		restrict: 'AEC',
		link: link,
		templateUrl: 'app/shared/toolbar/toolbar.html',
		scope: {
			page: '@',
			isCardResultView: '=', // Switch visualizzazione card/lista degli elementi
			forceToggleIndicator: '=', // Toggle indicatore di default (open, consumed, percentage...) / suggeritore
			isLearningPlan: '=', // verifica se l'oggetto è un learning plan
			isSuggestedPersonChecked: '&',
			isSuggestedPeopleIndeterminate: '&',
			toggleAllSuggestedPeople: '=',
			toggleAllTemplates: '=',
			addItemToCurrentLearningPlan: '&',
			removeSelectedItemsFromLearningPlan: '&',
			isLearningPlanEditingMode: '@',
			addSelectedItemsToCurrentLearningPlan: '&',
			isAtLeastOneItemChildChecked: '&',
			isOneTemplateChecked: '&',
			isAtLeastOneTemplateChecked: '&',
			moveForwardSelectedChildItems: '&',
			moveBackSelectedChildItems: '&',
			itemChildsCount: '@',
			selectedItems: '=',
			duplicateItem: '&',
			deleteProject: '&',
			deleteGroup: '&',
			addUsersToGroup: '&',
			removeUsersFromGroup: '&',
			removeSelectedGroups: '&',
			removeSelectedTemplates: '=',
			currentAdminTab: '=',
			textTemplateType: '=',
			deleteItemAdmin: '&',
			isProject: '@',
			canGoToEdit: '=',
			project: '=',
			breadcrumbs: '=',
			addVisibility: '=',
			isItemPublic: '=',
			isMyItem: '=',
			goToEditTemplate: '=',
			previewTemplate: '=',
			copyTemplate: '=',
			isTenantOwner: '='
		}
	};
	function link($scope: IToolbarDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Verifica se l'utente ha almeno un admin tenant
		if (GlobalApplicationData && GlobalApplicationData.jwtPayload && GlobalApplicationData.jwtPayload.user && GlobalApplicationData.jwtPayload.user.userTenant && GlobalApplicationData.jwtPayload.user.userTenant.adminTenants && GlobalApplicationData.jwtPayload.user.userTenant.adminTenants.length) {
			$scope.hasUserAtLeastOneAdminTenant = true;
		}
		// Collegamento all'oggetto principale
		$scope.libraryApplicationData = LibraryApplicationData;

		// Verifica se sono nella parte amministrativa della Library
		$scope.adminMode = $sessionStorage.adminMode;

		// Verifica se l'utente può gestire Progetti
		if (UserService.isUserAuthorized($scope.libraryApplicationData.constants.LIBRARY_ADMIN_MANAGE_PROJECT)) {
			$scope.canManageProject = true;
		}

		// Verifico se mostrare il pulsante back
		if ($scope.page != 'home' && $scope.page != 'homeAdmin' && !$scope.breadcrumbs) {
			$scope.showBackBtn = true;
		}

		// Verifico se devo disabilitare il pulsante per lo switch card/lista
		if ($scope.page == 'projectDetail' || ($scope.page == 'itemDetail' && !$scope.isLearningPlan)) {
			$scope.isToggleCardBtnDisabled = true;
		}

		// Verifico se nascondere le azioni
		if (!$scope.adminMode && ($scope.page == 'projectDetail' || $scope.page == 'sectionDetail')) {
			$scope.hideActions = true;
		}

		// Verifico se mostrare il pulsante per la creazione di un nuovo gruppo
		if ($scope.page === 'homeAdmin' && $scope.currentAdminTab == 2 && $scope.adminMode) {
			$scope.isNewGroupBtnVisible = true;

		}

		// Verifico se mostrare il pulsante per la creazione di un nuovo Item
		if ($scope.page === 'homeAdmin' && $scope.adminMode && $scope.currentAdminTab !== 4 && $scope.hasUserAtLeastOneAdminTenant) {
			$scope.isNewItemBtnVisible = true;
		}

		// Verifico se mostrare il pulsante per la creazione di un nuovo Template
		if ($scope.page === 'homeAdmin' && $scope.adminMode && $scope.currentAdminTab === 4) {
			$scope.isNewTemplateBtnVisible = true;
		}

		// Verifico se mostrare il pulsante per la creazione di un nuovo LP
		if ($scope.page === 'home' || $scope.page === 'homeAdmin' && $scope.currentAdminTab !== 4 && $scope.hasUserAtLeastOneAdminTenant) {
			$scope.isNewLearningPlanBtnVisible = true;
		}

		// Verifico se mostrare il pulsante per la creazione di un nuovo progetto
		if ($scope.page === 'homeAdmin' && $scope.canManageProject && $scope.adminMode && $scope.currentAdminTab !== 4 && $scope.hasUserAtLeastOneAdminTenant) {
			$scope.isNewProjectBtnVisible = true;
		}

		// Verifico se mostrare il pulsante di modifica
		if (($scope.isTenantOwner && (($scope.page === 'itemDetail' && $scope.isLearningPlan && $scope.canGoToEdit) || ($scope.page === 'itemDetail' && $scope.adminMode) || ($scope.page === 'projectDetail' && $scope.canManageProject && $scope.adminMode)))
			|| ($scope.page === 'groupDetail' && $scope.adminMode)) {
			$scope.isEditBtnVisible = true;
		}

		// Verifico se mostrare il pulsante per duplicare
		if ($scope.page === 'itemDetail' && $scope.isLearningPlan || ($scope.page == 'groupDetail' && $scope.adminMode)) {
			$scope.isDuplicateItemBtnVisible = true;
		}

		// Verifico se mostrare il pulsante per aggiungere l'oggetto ad una playlist
		if ($scope.page === 'newLearningPlan' || $scope.page == 'editLearningPlan' || ($scope.page == 'editSection' && $scope.adminMode)) {
			$scope.isAddAtomicItemBtnVisible = true;
		}

		// Verifico se mostrare il pulsante per aggiungere una sezione al progetto
		if ($scope.page === 'editProject' && $scope.adminMode) {
			$scope.isAddSectionToProjectBtnVisible = true;
		}

		// Verifico se mostrare il pulsante per aggiungere persone al gruppo
		if ($scope.page === 'editGroup' && $scope.adminMode) {
			$scope.isAddUsersToGroupBtnVisible = true;
		}

		// Verifico se mostrare il pulsante per suggerire l'item
		if ($scope.page === 'itemDetail' && !$scope.adminMode) {
			$scope.isShareItemBtnVisible = true;
		}

		// Verifico se mostrare il pulsante per aggiungere una visibilità ad un gruppo o ad un utente
		if ($scope.page === 'editProject' || ($scope.page === 'editLearningPlan' && $scope.adminMode) || ($scope.page === 'itemDetail' && $scope.adminMode) || $scope.page === 'editItem') {
			$scope.isAddVisibilityToUserOrGroupBtnVisible = true;
		}

		// Verifico se mostrare il pulsante per aggiungere gli elementi selezionati all'oggetto
		if ($scope.page === 'addItemToCurrentLearningPlan' || ($scope.page === 'addItemToCurrentSection' && $scope.adminMode)) {
			$scope.isAddItemToLearningPlanBtnVisible = true;
		}

		// Unwrap della funzione che elimina un progetto
		$scope.deleteProject = $scope.deleteProject();

		// Toggle visualizzazione lista/cards
		$scope.isToggleResultViewVisible = true;
		$scope.isToggleResultViewAutomaticChanged = false;
		$scope.isWidthExceeded = false;

		// Il pulsante per spostare un elemento avanti è disabilitato se almeno un elemento risulta essere già in ultima posizione
		$scope.moveForwardDisabled = () => {
			if ($scope.selectedItems) {
				for (let i = 0; i < $scope.selectedItems.length; i++) {
					if ($scope.selectedItems[i].childOrder == 1) {
						return true;
					}
				}
			}
		}

		// Il pulsante per spostare un elemento indietro è disabilitato se almeno un elemento risulta essere già in prima posizione
		$scope.moveBackDisabled = () => {
			if ($scope.selectedItems) {
				for (let i = 0; i < $scope.selectedItems.length; i++) {
					if ($scope.selectedItems[i].childOrder == $scope.itemChildsCount) {
						return true;
					}
				}
			}
		}

		// Id dell'oggetto
		$scope.itemId = $stateParams.itemId ? $stateParams.itemId : $stateParams.itemToAddId;

		// Mantiene lo stato di apertura/chiusura tooltip con la spiegazione delle icone
		$scope.isTooltipVisible = false;

		// Elenco di tutti i tooltip. Questo oggetto li racchiude tutti per controllarne la visibilità. E' necessario in quanto angular material ha un bug per cui
		// se si aggiungono più tooltip, si associano le relative l'md-visible a un'unica variabile, se si va sopra con il mouse (in hover) il tooltip esce ma poi
		// se premo il pulsante per espandere tutti i tooltip, questi non si visulizzano. Quindi l'unico modo è separare gli l'md-visible e racchiuderli in un unico oggetto
		$scope.tooltipsVisibility = {
			newItem: false,
			newGroup: false,
			addUsersToGroup: false,
			newLearningPlan: false,
			newProject: false,
			edit: false,
			duplicate: false,
			addAtomicObject: false,
			addSectionToProject: false,
			addToMyActivities: false,
			suggest: false,
			addObjToLearningPlan: false,
			sendToExtApp: false,
			selectAll: false,
			deselectAll: false,
			next: false,
			back: false,
			delete: false,
			deleteSection: false,
			removeFromMyActivities: false,
			goToHome: false,
			addVisibilityToUserOrGroup: false,
			newTemplate: false,
			editTemplate: false,
			previewTemplate: false,
			duplicateTemplate: false
			//addToMyActivities: false
		};

		// Verifica se è visibile il pulsante per la cancellazione
		$scope.isDeleteBtnVisible = (): boolean => {
			let isVisible: boolean = false;
			if (($scope.isTenantOwner && $scope.page === 'itemDetail' && $scope.adminMode) || ($scope.isTenantOwner && $scope.isLearningPlan && $scope.canGoToEdit)
				|| ($scope.page === 'editItem') || ($scope.page == 'editLearningPlan' && $scope.isAtLeastOneItemChildChecked())
				|| ($scope.page == 'homeAdmin' && $scope.isAtLeastOneItemChildChecked() && $scope.currentAdminTab == 2 && $scope.adminMode)
				|| ($scope.page == 'editGroup' && $scope.isAtLeastOneItemChildChecked() && $scope.adminMode)
				|| ($scope.page == 'groupDetail' && $scope.adminMode)
				|| ($scope.page == 'editProject' && !$scope.isAtLeastOneItemChildChecked() && $scope.adminMode)
				|| ($scope.page == 'editSection' && $scope.isAtLeastOneItemChildChecked() && $scope.adminMode)) {
				isVisible = true;
			}
			return isVisible;
		}

		// Da una certa larghezza dello schermo, la visualizzazione (se prevista) sarà solo quella a lista. 
		//Di conseguenza devo controllare al resize della pagina
		angular.element($window).bind('resize', () => {
			setTimeout(() => {
				$scope.$apply(() => {
					// Calcolo la larghezza della finestra
					$scope.windowWidth = $window.innerWidth;

					if ($scope.windowWidth < 680) {
						// se la finestra è minore di 600 px, nascondo il pulsante per lo switch di visualizzazione
						$scope.isToggleResultViewVisible = false;
						// e se ero in modalità card, eseguo lo switch nella lista
						if ($scope.isCardResultView == true) {
							$scope.toggleResultView();
							$scope.isToggleResultViewAutomaticChanged = true;
						}
						$scope.isWidthExceeded = true;
					} else if ($scope.isWidthExceeded == true) {
						// Rendo visibile il pulsante per cambiare la visualizzazione e, se precedentemente era stata cambiata la visualizzazione in automatico, la porto a com'era prima
						$scope.isToggleResultViewVisible = true;
						if ($scope.isToggleResultViewAutomaticChanged == true) {
							$scope.toggleResultView();
							$scope.isToggleResultViewAutomaticChanged = false;
						}
						$scope.isWidthExceeded = false;
					}
				});
			});
		});
		// e devo controllare al caricamento della pagina
		angular.element(document).ready(() => {
			// Calcolo la larghezza della finestra
			$scope.windowWidth = $window.innerWidth;

			if ($scope.windowWidth < 680) {
				// se la finestra è minore di 600 px, nascondo il pulsante per lo switch di visualizzazione
				$scope.isToggleResultViewVisible = false;
				// e se ero in modalità card, eseguo lo switch nella lista
				if ($scope.isCardResultView == true) {
					$scope.toggleResultView();
					$scope.isToggleResultViewAutomaticChanged = true;
				}
				$scope.isWidthExceeded = true;
			} else if ($scope.isWidthExceeded == true) {
				// Rendo visibile il pulsante per cambiare la visualizzazione e, se precedentemente era stata cambiata la visualizzazione in automatico, la porto a com'era prima
				$scope.isToggleResultViewVisible = true;
				if ($scope.isToggleResultViewAutomaticChanged == true) {
					$scope.toggleResultView();
					$scope.isToggleResultViewAutomaticChanged = false;
				}
				$scope.isWidthExceeded = false;
			}
		});

		// Apre o chiude i tooltip
		$scope.toggleTooltipVisibility = () => {
			$scope.tooltipsVisibility.newItem = !$scope.tooltipsVisibility.newItem;
			$scope.tooltipsVisibility.newGroup = !$scope.tooltipsVisibility.newGroup;
			$scope.tooltipsVisibility.addUsersToGroup = !$scope.tooltipsVisibility.addUsersToGroup;
			$scope.tooltipsVisibility.newLearningPlan = !$scope.tooltipsVisibility.newLearningPlan;
			$scope.tooltipsVisibility.newProject = !$scope.tooltipsVisibility.newProject;
			$scope.tooltipsVisibility.edit = !$scope.tooltipsVisibility.edit;
			$scope.tooltipsVisibility.duplicate = !$scope.tooltipsVisibility.duplicate;
			$scope.tooltipsVisibility.addAtomicObject = !$scope.tooltipsVisibility.addAtomicObject;
			$scope.tooltipsVisibility.addSectionToProject = !$scope.tooltipsVisibility.addSectionToProject;
			$scope.tooltipsVisibility.addToMyActivities = !$scope.tooltipsVisibility.addToMyActivities;
			$scope.tooltipsVisibility.suggest = !$scope.tooltipsVisibility.suggest;
			$scope.tooltipsVisibility.addObjToLearningPlan = !$scope.tooltipsVisibility.addObjToLearningPlan;
			$scope.tooltipsVisibility.sendToExtApp = !$scope.tooltipsVisibility.sendToExtApp;
			$scope.tooltipsVisibility.selectAll = !$scope.tooltipsVisibility.selectAll;
			$scope.tooltipsVisibility.deselectAll = !$scope.tooltipsVisibility.deselectAll;
			$scope.tooltipsVisibility.next = !$scope.tooltipsVisibility.next;
			$scope.tooltipsVisibility.back = !$scope.tooltipsVisibility.back;
			$scope.tooltipsVisibility.delete = !$scope.tooltipsVisibility.delete;
			$scope.tooltipsVisibility.deleteSection = !$scope.tooltipsVisibility.deleteSection;
			$scope.tooltipsVisibility.removeFromMyActivities = !$scope.tooltipsVisibility.removeFromMyActivities;
			$scope.tooltipsVisibility.goToHome = !$scope.tooltipsVisibility.goToHome;
			$scope.tooltipsVisibility.addVisibilityToUserOrGroup = !$scope.tooltipsVisibility.addVisibilityToUserOrGroup;
			$scope.tooltipsVisibility.newTemplate = !$scope.tooltipsVisibility.newTemplate;
			$scope.tooltipsVisibility.editTemplate = !$scope.tooltipsVisibility.editTemplate;
			$scope.tooltipsVisibility.previewTemplate = !$scope.tooltipsVisibility.previewTemplate;
			$scope.tooltipsVisibility.dupliaceTemplate = !$scope.tooltipsVisibility.duplicateTemplate;
		}

		// Verifico se mostra il pulsante per lo switch card/lista
		if ($scope.isToggleResultViewVisible && $scope.isCardResultView !== undefined) {
			$scope.showToggleCardBtn = true;
		}

		// Porta alla pagina per la creazione di un nuovo gruppo
		$scope.goToNewGroup = () => {
			$state.go('app.libraryApp.newGroup');
		}

		// Porta alla pagina per la creazione di un nuovo template
		$scope.goToNewTemplate = () => {
			$state.go('app.libraryApp.newTemplate', { textTemplateType: $scope.textTemplateType });
		}

		// Porta alla pagina per la creazione di un nuovo Learning Plan
		$scope.goToNewLearningPlan = () => {
			if ($sessionStorage.adminMode) {
				$state.go('app.libraryApp.newLearningPlanAdmin', { itemId: $scope.itemId });
			} else {
				$state.go('app.libraryApp.newLearningPlan', { itemId: $scope.itemId });
			}
		}

		// Porta alla paginape l'editing di un progetto
		$scope.goToEditProject = () => {
			$state.go('app.libraryApp.editProject', { itemId: $scope.itemId });
		}
		// Porta alla paginape l'editing di un gruppo
		$scope.goToEditGroup = () => {
			$state.go('app.libraryApp.editGroup', { groupId: $stateParams.groupId });
		}

		// Porta alla pagina per la creazione di una sezione da aggiungere al progetto
		$scope.addSectionToProject = () => {
			$state.go('app.libraryApp.newSection', { itemId: $scope.itemId });
		}

		// Porta alla pagina per la creazione di un nuovo Item
		$scope.goToNewItem = () => {
			$state.go('app.libraryApp.chooseItem');
		}

		// Apre una modale che permette di aggiunge l'oggetto al Learning Plan. Fa scegliere se aggiungere l'oggetto a un LP esisten o da creare
		$scope.addItemToLearningPlan = (event: any) => {
			$mdDialog.show({
				controller: this.AddItemToLPController,
				templateUrl: 'addItemToLP.html',
				parent: angular.element(document.body),
				targetEvent: event,
				clickOutsideToClose: false,
				locals: {
					itemId: $scope.itemId
				}
			})
				.then((selectedLearningPlan: Item) => {
					// Blocco la schermata
					blockUI.start();

					// Creo il contenitore dei childs
					let childsId: Array<string> = [];

					// e ci aggiungo l'id dell'oggetto
					childsId.push($scope.itemId);

					// Aggiungo la visibilità dell'oggetto corrente all'utente selezionato
					LearningPlanService.addChilds.query({
						parentId: selectedLearningPlan.itemId,
						childsId: childsId
					}).$promise
						.then((data: SenecaResponse<Item>) => {
							// Se c'è segnalo l'errore
							if (data.error) {
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								toaster.pop("success", $translate.instant('generic.OPERATION_PERFORMED'), $translate.instant('toolbar.toaster.ADDED_TO_LP'));
							}
							// Sblocco la schermata
							blockUI.stop();
						})
						.catch(function (error: any) {
							// Sblocco la schermata
							blockUI.stop();
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}, function () {
					// In caso di dismiss non faccio niente
				});
		}

		// Controller per la modale che aggiunge l'oggetto al Learning Plan. Fa scegliere se aggiungere l'oggetto a un LP esisten o da creare
		this.AddItemToLPController = ($scope: any, $mdDialog: any, $stateParams: any, itemId: any) => {
			// Toggle menu aggiunta item a un learning nuovo oppure esistente / lista learning plan creati dall'utente
			$scope.addToLpMenuVisible = true;

			// Learning Plan selezionato a cui aggiungere l'elemento
			$scope.selectedLearningPlan = null;

			// Item dell'oggetto che sto aggiungendo
			$scope.currentItemId = itemId;

			// BlockUI della ricerca dei Learning Plan
			$scope.searchLearningPlanBlockUI = blockUI.instances.get('searchLearningPlanBlockUI');

			// Dato che l'infinite scroll appende i dati, devo partire con una lista esistente vuota
			$scope.searchResultLearningPlan = [];

			// Titolo del Learning Plan che viene aggiunto come filtro
			$scope.searchedLearningPlanTitle = '';

			// Variabili che bloccano l'infinite scroll finché sta caricando o la pagina non è pronta
			$scope.learningPlaninfiniteScrollEnabled = false;
			$scope.learningPlanIsInfiniteScrollLoading = false;
			$scope.noLearningPlanFound = false;

			// Count totale dei Learning Plan recuperati
			$scope.totalLearningPlanCount = null;

			// Dato che l'infinite scroll appende i dati, devo partire con una lista esistente vuota
			$scope.learningPlanInfiniteScrollLoadCount = 5;
			$scope.learningPlanInfiniteScrollStartOffSet = 0;
			$scope.totalLearningPlansLoaded = 0;

			// Eseguo la ricerca dei Learning Plan esistenti
			$scope.getAllMyLearningPlan = (newSearch?: boolean) => {
				$scope.learningPlanIsInfiniteScrollLoading = true;

				// Disabilito i pulsanti
				$scope.isDoingSomething = true;

				// Se non ce l'ho già, recupero il count del totale degli elementi recuperati
				if (newSearch) {
					$scope.totalLearningPlanCount = 0;
					$scope.searchResultLearningPlan.length = 0;
					$scope.totalLearningPlansLoaded = 0;
					$scope.residualPersonsInTenantCount = 0;
					$scope.learningPlanInfiniteScrollStartOffSet = 0;
				}

				if ($scope.countLearningPlansPromise) {
					$scope.countLearningPlansPromise.$cancelRequest();
				}
				$scope.countLearningPlansPromise =
					LearningPlanService.countAllMyLearningPlans.query({
						title: $scope.searchedLearningPlanTitle
					});
				$scope.countLearningPlansPromise.$promise
					.then((data: SenecaResponse<number>) => {
						if (data.error) {
							// Dati non validi, quindi alzo l'errore
							toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
						} else {
							$scope.residualPersonsInTenantCount += data.response ? data.response : 0;
							// Torno i risultati della ricerca
							$scope.totalLearningPlanCount = data.response;

							if ($scope.totalLearningPlanCount == 0) {
								$scope.noLearningPlanFound = true;
							}

							// Abilito la ricerca se mi mancano ancora altri dati da visualizzare
							if (($scope.learningPlanInfiniteScrollStartOffSet < $scope.totalLearningPlanCount)) {

								// Avvio lo loader
								$scope.searchLearningPlanBlockUI.start();

								if ($scope.getAllMyLearningPlanPromise) {
									$scope.getAllMyLearningPlanPromise.$cancelRequest();
								}
								$scope.getAllMyLearningPlanPromise =
									LearningPlanService.getAllMyLearningPlans.query({
										title: $scope.searchedLearningPlanTitle,
										fromRecord: $scope.learningPlanInfiniteScrollStartOffSet,
										numRecords: $scope.learningPlanInfiniteScrollLoadCount
									});
								$scope.getAllMyLearningPlanPromise.$promise
									.then((data: SenecaResponse<Array<User>>) => {
										if (data.error) {
											// Dati non validi, quindi alzo l'errore
											toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
										} else {
											$scope.residualPersonsInTenantCount -= data.response.length;
											// Aggiungo gli utenti trovato con la ricerca
											$scope.searchResultLearningPlan = $scope.searchResultLearningPlan.concat(data.response);
											$scope.totalLearningPlansLoaded = $scope.searchResultLearningPlan.length;
											// Mi segno che sono arrivato a questo punto della lista
											$scope.learningPlanInfiniteScrollStartOffSet += data.response.length;
											if (!$scope.searchResultLearningPlan.length) {
												$scope.noLearningPlanFound = true;
											}

											// Se trovo un Learning Plan in cui è già stato aggiunto l'oggetto corrente, lo segnalo affinché venga disabilitato il pulsante di selezione
											for (let i = 0; i < $scope.searchResultLearningPlan.length; i++) {
												if ($scope.searchResultLearningPlan[i].itemChilds && $scope.searchResultLearningPlan[i].itemChilds.length) {
													for (let k = 0; k < $scope.searchResultLearningPlan[i].itemChilds.length; k++) {
														if ($scope.searchResultLearningPlan[i].itemChilds[k].referenceId == $scope.currentItemId) {
															$scope.searchResultLearningPlan[i].itemAlreadyPresent = true;
														}
													}
												}
											}

											// Visto che il rendering HTML in pagina potrebbe metterci un po', per evitare che l'infiniteScroll si invochi di nuovo
											// dato che la pagina non si è ancora riempita, il loading falso lo imposto dopo qualche millisencondo
											// Contemporaneamente abilito anche l'infinite scroll, che finora è stato spento perché la prima richiesta deve essere fatta manualmente
											$timeout(() => {
												$scope.learningPlaninfiniteScrollEnabled = true;
												$scope.learningPlanIsInfiniteScrollLoading = false;
											}, 500);
										}
										// Annullo la promessa
										$scope.getAllMyLearningPlanPromise = null;
										// E disattivo il loader
										$scope.searchLearningPlanBlockUI.stop();
										// Abilito i pulsanti
										$scope.isDoingSomething = false;
									})
									.catch((error: any) => {
										// Annullo la promessa
										$scope.getAllMyLearningPlanPromise = null;
										// Abilito i pulsanti
										$scope.isDoingSomething = false;
										// E disattivo il loader
										$scope.searchLearningPlanBlockUI.stop();
										// Non mostro la modale di errore se ho cancellato volutamente la richiesta
										if (!error || error.config.timeout.$$state.status !== 1) {
											// Verifico se è un problema di connettività
											let errorMessage: string = null;

											// Nuovo oggetto d'errore
											let newError: any = {
												severity: "danger"
											}

											if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
												// Problema di connettività
												errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
												newError.hideUnknown = true;
											} else {
												// Messaggio di errore generico
												errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
											}

											// Imposto il messaggio
											newError.message = errorMessage;

											// Lo aggiungo alla lista
											let errors: Array<any> = [];
											errors.push(newError);

											// E apro la modale
											this.$rootScope.$emit("showApplicationModalErrors", errors);
										}
									});
							} else {
								// Abilito i pulsanti
								$scope.isDoingSomething = false;
							}
						}
						// Annullo la promessa
						$scope.countLearningPlansPromise = null;
					})
					.catch((error: any) => {
						// Annullo la promessa
						$scope.countLearningPlansPromise = null;
						// Non mostro la modale di errore se ho cancellato volutamente la richiesta
						if (!error || error.config.timeout.$$state.status !== 1) {
							// Verifico se è un problema di connettività
							let errorMessage: string = null;

							// Nuovo oggetto d'errore
							let newError: any = {
								severity: "danger"
							}

							if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
								// Problema di connettività
								errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
								newError.hideUnknown = true;
							} else {
								// Messaggio di errore generico
								errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
							}

							// Imposto il messaggio
							newError.message = errorMessage;

							// Lo aggiungo alla lista
							let errors: Array<any> = [];
							errors.push(newError);

							// E apro la modale
							this.$rootScope.$emit("showApplicationModalErrors", errors);
						}
					});
			}

			// Salvo la persona a cui voglio suggerire l'elemento
			$scope.selectLearningPlan = (selectedLearningPlan: User) => {
				$scope.selectedLearningPlan = selectedLearningPlan;
			};

			$scope.showAllMyLearningPlans = () => {
				// Toggle dal menu alla lista dei learning plan
				$scope.addToLpMenuVisible = false;
				// ed eseguo la ricerca
				$scope.getAllMyLearningPlan(true);
			}

			// Torno alla ricerca degli utenti
			$scope.backToList = () => {
				// Deseleziono l'utente
				$scope.selectedLearningPlan = null;
			}

			// Chiude la modale annullando l'operazione
			$scope.cancel = () => {
				$mdDialog.cancel();
			};

			// Chiude la modale annullando l'operazione
			$scope.confirm = () => {
				// Mi assicuro che sia stato selezionato un learning plan per evitare l'aggiunta vuota alla pressione del tasto INVIO della tastiera sull'input di ricerca
				if (!$scope.selectedLearningPlan) {
					return;
				}
				$mdDialog.hide($scope.selectedLearningPlan);
			};

			// Vado alla creazione di un nuovo learning plan in cui aggiungerò l'oggetto selezionato
			$scope.goToNewLearningPlan = () => {
				$mdDialog.cancel();
				if ($scope.adminMode) {
					$state.go('app.libraryApp.newLearningPlanAdmin', { itemId: $scope.itemId });
				} else {
					$state.go('app.libraryApp.newLearningPlan', { itemId: $scope.itemId });
				}
			};
		}

		// Porta alla pagina di modifica di un Item
		$scope.goToEditItem = () => {
			if ($scope.adminMode && $scope.isLearningPlan) {
				$state.go('app.libraryApp.editLearningPlanAdmin', { itemId: $scope.itemId });
			} else if (!$scope.adminMode && $scope.isLearningPlan) {
				$state.go('app.libraryApp.editLearningPlan', { itemId: $scope.itemId });
			} else {
				$state.go('app.libraryApp.editItem', { itemId: $scope.itemId });
			}
		}

		// Porta alla pagina di modifica del Learning Plan
		$scope.goToNewProject = () => {
			$state.go('app.libraryApp.newProject');
		};

		$scope.historyBack = () => {
			// Se sono nella pagina di un dettaglio del gruppo, torno alla home con la tab aperta
			if ($scope.page == 'groupDetail') {
				// Verifico quanti elementi ho nel Session Storage
				let count = SessionStorageService.count();

				// ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
				count = count ? count - 1 : null;

				$state.go('app.libraryApp.homeAdmin', { searchId: count });
			} else if ($window.history.length <= 1) {
				// non faccio nulla
			} else {
				$window.history.back();
			}
		}

		// Cambia la visualizzazione dei risultati card/list
		$scope.toggleResultView = () => {
			$scope.isCardResultView = !$scope.isCardResultView;
			// Salvo anche nel session storage
			$sessionStorage.isCardResultView = $scope.isCardResultView;
		}

		// Toggle indicatore di default (open, consumed, percentage...) / suggeritore
		$scope.toggleIndicatorView = () => {
			$scope.forceToggleIndicator = !$scope.forceToggleIndicator;
		}

		// Apre una modale che permette di cercare una persona condividere con lui un item
		$scope.shareItem = (event: any) => {
			$mdDialog.show({
				controller: this.ShareItemController,
				templateUrl: 'shareItem.html',
				parent: angular.element(document.body),
				targetEvent: event,
				clickOutsideToClose: false,
				fullscreen: true,
				locals: {
					isItemPublic: $scope.isItemPublic,
					isLearningPlan: $scope.isLearningPlan,
					isMyItem: $scope.isMyItem
				}
			})
				.then((selectedUser: User) => {
					// Blocco la schermata
					blockUI.start();

					// Aggiungo la visibilità dell'oggetto corrente all'utente selezionato
					VisibilityService.addSuggestion.query({
						destinationUser: selectedUser,
						itemId: $scope.itemId
					}).$promise
						.then((data: SenecaResponse<Item>) => {
							// Se c'è segnalo l'errore
							if (data.error) {
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								toaster.pop("success", $translate.instant('generic.OPERATION_PERFORMED'), $translate.instant('toolbar.toaster.SUGGESTED'));
							}
							// Sblocco la schermata
							blockUI.stop();
						})
						.catch(function (error: any) {
							// Sblocco la schermata
							blockUI.stop();
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}, function () {
					// In caso di dismiss non faccio niente
				});
		}

		// Controller per la modale del rifiuto del piano
		this.ShareItemController = ($scope: any, $mdDialog: any, isItemPublic: boolean, isLearningPlan: boolean, isMyItem: boolean) => {
			// Verifica se l'oggetto possiede almeno una visibilità pubblica
			$scope.isItemPublic = isItemPublic;

			// Verifica se ho creato io l'oggetto
			$scope.isMyItem = isMyItem;

			// Verifica se sto suggerendo un learning plan
			$scope.isLearningPlan = isLearningPlan;

			// Utente selezionato a cui suggerire l'elemento
			$scope.selectedPerson = null;
			// BlockUI della ricerca degli utenti
			$scope.searchBlockUI = blockUI.instances.get('searchBlockUI');

			// Dato che l'infinite scroll appende i dati, devo partire con una lista esistente vuota
			$scope.searchResult = [];

			// Testo della ricerca
			$scope.searchedText = '';

			// Variabili che bloccano l'infinite scroll finché sta caricando o la pagina non è pronta
			$scope.infiniteScrollEnabled = false;
			$scope.isInfiniteScrollLoading = false;
			$scope.noDataFound = false;

			// Count totale degli elementi trovati
			$scope.totalItemsCount = null;

			// Dato che l'infinite scroll appende i dati, devo partire con una lista esistente vuota
			$scope.infiniteScrollLoadCount = 12;
			$scope.infiniteScrollStartOffSet = 0;
			$scope.totalItemsLoaded = 0;

			// Eseguo la ricerca delle persone nel mio tenant, eliminando le eventuali richieste pendenti
			$scope.getPersonsInTenant = (newSearch?: boolean) => {
				$scope.isInfiniteScrollLoading = true;

				// Disabilito i pulsanti
				$scope.isDoingSomething = true;

				// Avvio lo loader
				$scope.searchBlockUI.start();

				// Se non ce l'ho già, recupero il count del totale degli elementi recuperati
				if (newSearch && $scope.searchedText && $scope.searchedText.length) {
					if (newSearch) {
						$scope.totalItemsCount = 0;
						$scope.searchResult.length = 0;
						$scope.totalItemsLoaded = 0;
						$scope.residualPersonsInTenantCount = 0;
						$scope.infiniteScrollStartOffSet = 0;
					}

					if ($scope.countItemPromise) {
						$scope.countItemPromise.$cancelRequest();
					}
					$scope.countItemPromise =
						VisibilityService.countPersonsInTenant.query({
							searchedText: $scope.searchedText
						});
					$scope.countItemPromise.$promise
						.then((data: SenecaResponse<number>) => {
							if (data.error) {
								// Dati non validi, quindi alzo l'errore
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								$scope.residualPersonsInTenantCount += data.response ? data.response : 0;
								// Torno i risultati della ricerca
								$scope.totalItemsCount = data.response;
							}
							// Annullo la promessa
							$scope.countItemPromise = null;
						})
						.catch((error: any) => {
							// Annullo la promessa
							$scope.countItemPromise = null;
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}

				// Abilito la ricerca solo se è la prima volta o se mi mancano ancora altri dati da visualizzare
				if ((newSearch || $scope.infiniteScrollStartOffSet < $scope.totalItemsCount) && $scope.searchedText && $scope.searchedText.length) {

					if (newSearch) {
						$scope.searchResult.length = 0;
						$scope.totalItemsLoaded = 0;
						$scope.residualPersonsInTenantCount = 0;
						$scope.infiniteScrollStartOffSet = 0;
					}

					if ($scope.getPersonsInTenantPromise) {
						$scope.getPersonsInTenantPromise.$cancelRequest();
					}
					$scope.getPersonsInTenantPromise =
						VisibilityService.getPersonsInTenant.query({
							searchedText: $scope.searchedText,
							fromRecord: $scope.infiniteScrollStartOffSet,
							numRecords: $scope.infiniteScrollLoadCount
						});
					$scope.getPersonsInTenantPromise.$promise
						.then((data: SenecaResponse<Array<User>>) => {
							if (data.error) {
								// Dati non validi, quindi alzo l'errore
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								$scope.residualPersonsInTenantCount -= data.response.length;
								// Aggiungo gli utenti trovato con la ricerca
								$scope.searchResult = $scope.searchResult.concat(data.response);
								$scope.totalItemsLoaded = $scope.searchResult.length;
								// Mi segno che sono arrivato a questo punto della lista
								$scope.infiniteScrollStartOffSet += data.response.length;
								if (!$scope.searchResult.length) {
									$scope.noDataFound = true;
								}

								// Visto che il rendering HTML in pagina potrebbe metterci un po', per evitare che l'infiniteScroll si invochi di nuovo
								// dato che la pagina non si è ancora riempita, il loading falso lo imposto dopo qualche millisencondo
								// Contemporaneamente abilito anche l'infinite scroll, che finora è stato spento perché la prima richiesta deve essere fatta manualmente
								$timeout(() => {
									$scope.infiniteScrollEnabled = true;
									$scope.isInfiniteScrollLoading = false;
								}, 500);
							}
							// Annullo la promessa
							$scope.getPersonsInTenantPromise = null;
							// E disattivo il loader
							$scope.searchBlockUI.stop();
							// Abilito i pulsanti
							$scope.isDoingSomething = false;
						})
						.catch((error: any) => {
							// Annullo la promessa
							$scope.getPersonsInTenantPromise = null;
							// Abilito i pulsanti
							$scope.isDoingSomething = false;
							// E disattivo il loader
							$scope.searchBlockUI.stop();
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				} else {
					// Abilito i pulsanti
					$scope.isDoingSomething = false;
					// Disattivo il blockUI
					$scope.searchBlockUI.stop();
				}
			}

			// Salvo la persona a cui voglio suggerire l'elemento
			$scope.selectPersonToSuggest = (selectedPerson: User) => {
				$scope.selectedPerson = selectedPerson;
			};

			// Torno alla ricerca degli utenti
			$scope.backToList = () => {
				// Deseleziono l'utente
				$scope.selectedPerson = null;
			}

			// Chiude la modale annullando l'operazione
			$scope.cancel = () => {
				$mdDialog.cancel();
			};

			// Confermo l'operazione e chiudo il piano
			$scope.confirm = () => {
				// Per prevenire l'azione all'invio nella barra di ricerca, mi assicuro di avere un utente selezionato
				if (!$scope.selectedPerson) {
					return;
				}
				$mdDialog.hide($scope.selectedPerson);
			};
		}

		// Apre una modale che chiede conferma sulla rimozione del Learning Plan
		$scope.deleteItem = (event: any) => {
			$mdDialog.show({
				controller: this.DeleteItemController,
				templateUrl: 'deleteItem.html',
				parent: angular.element(document.body),
				targetEvent: event,
				clickOutsideToClose: false
			})
				.then(() => {
					// Blocco la schermata
					blockUI.start();

					// Rimuovo l'oggetto
					LearningPlanService.deleteLearningPlan.query({
						itemId: $scope.itemId,
						removeAtomicItemsToo: false
					}).$promise
						.then((data: SenecaResponse<Item>) => {
							// Se c'è segnalo l'errore
							if (data.error) {
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								toaster.pop("success", $translate.instant('generic.OPERATION_PERFORMED'), $translate.instant('toolbar.toaster.LP_DELETED'));
								// Vado nella home
								if ($scope.adminMode) {
									$state.go('app.libraryApp.homeAdmin', { searchId: null });
								} else {
									$scope.goToHome();
								}
							}
							// Sblocco la schermata
							blockUI.stop();
						})
						.catch(function (error: any) {
							// Sblocco la schermata
							blockUI.stop();
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}, function () {
					// In caso di dismiss non faccio niente
				});
		}

		// Controller per la modale che chiede la conferma per l'eliminazione dell'oggetto
		this.DeleteItemController = ($scope: any, $mdDialog: any) => {
			// Chiude la modale annullando l'operazione
			$scope.cancel = () => {
				$mdDialog.cancel();
			};

			// Confermo l'operazione e rimuovo l'oggetto
			$scope.confirm = () => {
				$mdDialog.hide();
			};
		}

		// Porta alla home page
		$scope.goToHome = () => {
			$state.go('app.libraryApp.home', { searchId: null });
		};
	}
});